import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { PickupManifestPresenter } from "@/app/ui/presenters/PickupManifestPresenter";
import {
  PickupManifestEntities,
  Drivers,
  STTPiecesDetail,
  PrintDataList,
  DetailSTT,
  SttListChecklistEntities
} from "@/domain/entities/PickupManifest";
import {
  AddDriverRequest,
  UpdatePickupRequest
} from "@/data/payload/api/PickupManifestApiRequest";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface PickupManifestState {
  isLoading: boolean;
  errorModal: boolean;
  errorCause: string;
  isLoadingGeDrivers: boolean;
  isLoadingPrintDataList: boolean;
  isLoadingUpdate: boolean;
  driverList: Drivers[];
  printDataList: PrintDataList;
  isErrorPrintList: boolean;
  isErrorCausePrintList: string;
  isErrorUpdate: boolean;
  isErrorCauseUpdate: string;
  pickupManifestId: number;
}

@Module({ namespaced: true, dynamic: true, store, name: "pickup_manifest" })
class PickupManifestStore extends VuexModule implements PickupManifestState {
  public isLoading = false;
  public periodeStart: any = null;
  public periodeEnd: any = null;
  public isLoadingGeDrivers = false;
  public isLoadingPrintDataList = false;
  public isLoadingUpdate = false;
  public driverList: Array<Drivers> = [];
  public isErrorPrintList = false;
  public isErrorCausePrintList = "";
  public printDataList = new PrintDataList(
    0,
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    0,
    0,
    0,
    0,
    0,
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    "",
    [],
    ""
  );
  public pickupManifestList = new PickupManifestEntities(
    new Pagination(0, 0),
    []
  );
  public sttListBkd = new SttListChecklistEntities(new Pagination(0, 0), []);
  public errorModal = false;
  public errorCause = "";
  public isErrorUpdate = false;
  public isErrorCauseUpdate = "";
  public pickupManifestId = 0;
  public sttFailedUpdate: Array<any> = [];

  @Action
  public addDriver(params: { name: string; phone: string }) {
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .addDriver(new AddDriverRequest(params.name, params.phone))
      .then(() => {
        this.setErrorModal(false);
        this.setErrorCause("");
        return true;
      })
      .catch((err: any) => {
        this.setErrorModal(true);
        this.setErrorCause(err.response ? "server" : "internet");
        return false;
      });
  }

  @Action
  public getListPickupManifest(params: {
    page: number;
    limit: number;
    startDate: string;
    endDate: string;
  }) {
    this.setIsLoading(true);
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .getListPickupManifest(
        params.page,
        params.limit,
        params.startDate,
        params.endDate
      )
      .then((res: PickupManifestEntities) => {
        this.setPickupManifestList(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setPickupManifestList(
          new PickupManifestEntities(new Pagination(0, 0), [])
        );
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public getSttListChecklist(params: {
    page: number;
    limit: number;
    status: string;
    date: string;
    sortBy: string;
    productType: string;
    cache: boolean
  }) {
    this.setSttListBkd(
      new SttListChecklistEntities(new Pagination(1, 500), [])
    );
    this.setIsLoading(true);
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .getSttListChecklist(
        params.page,
        params.limit,
        params.status,
        params.date,
        params.sortBy,
        params.productType,
        params.cache
      )
      .then(res => {
        this.setSttListBkd(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(
          err.response
            ? err.response.status < 500
              ? "notfound"
              : "server"
            : "internet"
        );
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public async fetchPickupManifestList() {
    await this.getListPickupManifest({
      page: this.pickupManifestList.pagination.page,
      limit: this.pickupManifestList.pagination.limit,
      startDate: this.periodeStart
        ? this.periodeStart.toISOString().slice(0, 10)
        : "",
      endDate: this.periodeEnd ? this.periodeEnd.toISOString().slice(0, 10) : ""
    });
  }

  @Mutation
  public setPickupManifestList(value: PickupManifestEntities) {
    this.pickupManifestList = value;
  }

  @Action
  public getDetailSttPieces(sttPiece: string) {
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .getDetailSTTPieces(sttPiece)
      .then((res: STTPiecesDetail) => {
        return res;
      })
      .catch(() => {
        return null;
      });
  }

  @Action
  public getDetailStt(params: { sttNumber: string; }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .getDetailStt(params.sttNumber)
      .then((res: DetailSTT) => {
        return res;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Tambah No. STT Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.getDetailStt(params);
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public validateSttPiece(sttPiece: string) {
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .validationScanSTTPiece(sttPiece)
      .then(() => {
        return {
          allowToPickup: true,
          errorMessage: ""
        };
      })
      .catch((err: any) => {
        return {
          allowToPickup: false,
          errorMessage:
            err.response.data.message.id || "No. STT Pieces tidak ditemukan"
        };
      });
  }

  @Action
  public async getDrivers() {
    this.setLoadingGetDrivers(true);
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .getDrivers()
      .then((res: Drivers[]) => {
        this.setGetDrivers(res);
      })
      .catch(() => {
        this.setGetDrivers([]);
      })
      .finally(() => {
        this.setLoadingGetDrivers(false);
      });
  }

  @Action
  public getPrintDataList(params: { id: number }) {
    this.setLoadingPrintDataList(true);
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .getPrintDataList(params.id)
      .then((res: PrintDataList) => {
        this.setGetPrintDataList(res);
        return true;
      })
      .catch(error => {
        this.setErrorPrintList(true);
        this.setErrorCausePrintList(error.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setLoadingPrintDataList(false);
      });
  }

  @Action
  public updatePickup(params: {
    originCityId: string;
    partnerId: number;
    driverId: number;
    vehicleId: number;
    sttTobePickup: Array<any>;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PickupManifestPresenter);
    return presenter
      .updatePickup(
        new UpdatePickupRequest(
          params.originCityId,
          params.partnerId,
          params.driverId,
          params.vehicleId,
          params.sttTobePickup
        )
      )
      .then((res: ResponsePayload) => {
        this.setPickupManifestId(res.pickupManifestId);
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.updatePickup(params);
          })
        );
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setLoadingGetDrivers(value: boolean) {
    this.isLoadingGeDrivers = value;
  }

  @Mutation
  public setLoadingPrintDataList(value: boolean) {
    this.isLoadingPrintDataList = value;
  }

  @Mutation
  public setLoadingUpdate(value: boolean) {
    this.isLoadingUpdate = value;
  }

  @Mutation
  public setGetDrivers(data: Drivers[]) {
    this.driverList = data;
  }

  @Mutation
  private setGetPrintDataList(data: PrintDataList) {
    this.printDataList = data;
  }

  @Mutation
  public setErrorPrintList(val: boolean) {
    this.isErrorPrintList = val;
  }

  @Mutation
  public setErrorCausePrintList(val: string) {
    this.isErrorCausePrintList = val;
  }

  @Mutation
  public setPeriodeStart(value: Date | null) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: Date | null) {
    this.periodeEnd = value;
  }

  @Mutation
  public setErrorModal(value: boolean) {
    this.errorModal = value;
  }

  @Mutation
  public setErrorUpdate(value: boolean) {
    this.isErrorUpdate = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setErrorCauseUpdate(value: string) {
    this.isErrorCauseUpdate = value;
  }

  @Mutation
  public setPickupManifestId(value: number) {
    this.pickupManifestId = value;
  }

  @Mutation
  public setSttFailedUpdate(value: Array<any>) {
    this.sttFailedUpdate = value;
  }

  @Mutation
  public setSttListBkd(val: SttListChecklistEntities) {
    this.sttListBkd = val;
  }
}

export const PickupManifestController = getModule(PickupManifestStore);
