/* eslint-disable @typescript-eslint/camelcase */

import {
  AddDriverRequestInterface,
  UpdatePickupRequestInterface
} from "@/data/payload/contracts/PickupManifestRequest";

export class AddDriverRequest implements AddDriverRequestInterface {
  private readonly driverName: string;
  private readonly driverPhone: string;

  constructor(driverName: string, driverPhone: string) {
    this.driverName = driverName;
    this.driverPhone = driverPhone;
  }
  public toJSON(): string {
    return JSON.stringify({
      driver_name: this.driverName,
      driver_phone: this.driverPhone
    });
  }
}

export class UpdatePickupRequest implements UpdatePickupRequestInterface {
  private originCityId: string;
  private partnerId: number;
  private driverId: number;
  private vehicleId: number;
  private sttTobePickup: Array<any>;

  constructor(
    originCityId: string,
    partnerId: number,
    driverId: number,
    vehicleId: number,
    sttTobePickup: Array<any>
  ) {
    this.originCityId = originCityId;
    this.partnerId = partnerId;
    this.driverId = driverId;
    this.vehicleId = vehicleId;
    this.sttTobePickup = sttTobePickup;
  }
  public toJSON(): string {
    return JSON.stringify({
      origin_id: this.originCityId,
      partner_id: this.partnerId,
      driver_id: this.driverId,
      vehicle_id: this.vehicleId,
      stt_no: this.sttTobePickup
    });
  }
}
